import { theme } from "@styles/theme";
import styled from "styled-components";

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  /* The 80 is the alpha channel. this way we can use the colour from the theme and make it transparent */
  background-color: ${theme.colors.BLACK}80;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 16;
`;

export const Window = styled.div<{
  width?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  ignoreHeightAndScroll?: boolean;
}>`
  margin: auto;
  width: ${({ width }) => (width ? width : "50vw")};
  max-width: ${({ width }) => (width ? width : "680px")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "auto")};
  position: relative;

  & > div {
    overflow-y: ${({ ignoreHeightAndScroll }) =>
      ignoreHeightAndScroll ? "" : "auto"};
    ${({ height, minHeight, ignoreHeightAndScroll }) =>
      ignoreHeightAndScroll
        ? null
        : height
        ? `height: ${height}`
        : `min-height: ${minHeight}`};
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "80vh")};
  }
`;

export const CloseButton = styled.button`
  background-color: ${theme.colors.SLATE100};
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  cursor: pointer;
  z-index: 1;
`;
